// src/services/api.js
const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://gamecoins4less-backend.onrender.com';

class ApiError extends Error {
  constructor(message, status, code, retryAfter = null) {
    super(message);
    this.status = status;
    this.code = code;
    this.retryAfter = retryAfter;
  }
}

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json().catch(() => null);
    throw new ApiError(
      getDefaultErrorMessage(response.status, errorData),
      response.status,
      errorData?.error || 'API_ERROR',
      errorData?.error?.retryAfter || null
    );
  }

  const data = await response.json();
  
  // Log raw response for debugging
  console.log('API raw response:', {
    status: response.status,
    data,
    timestamp: new Date().toISOString()
  });

  // Validate response data
  if (!data || !data.sources) {
    throw new ApiError(
      'Invalid response from server',
      500,
      'INVALID_RESPONSE'
    );
  }

  return data;
};

const getDefaultErrorMessage = (status, errorData) => {
  // Check for specific error messages from backend
  if (errorData?.error?.message) {
    if (errorData.error.message.includes('updating')) {
      return errorData.error.details || 'Price service is currently updating. Please try again in a few minutes.';
    }
    if (errorData.error.message.includes('stale')) {
      return errorData.error.details || 'Price data is being refreshed. Please try again in a few minutes.';
    }
    return errorData.error.message;
  }

  // Default status code messages
  switch (status) {
    case 400:
      return 'Invalid request. Please check your input.';
    case 403:
      return 'Access denied. Please try again later.';
    case 404:
      return 'No prices found for your selection. Please try again in a few minutes while we update our prices.';
    case 429:
      return 'Too many requests. Please wait a moment and try again.';
    case 503:
      return 'Price service is temporarily unavailable. Our system is gathering the latest prices. Please try again in a few minutes.';
    case 500:
      return 'Server error. Our team has been notified.';
    default:
      return 'Unable to fetch prices at the moment. Please try again.';
  }
};

const makeRequest = async (url) => {
  try {
    console.log('Making API request:', {
      url,
      timestamp: new Date().toISOString()
    });

    // GET request with CORS credentials
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const data = await handleResponse(response);

    // Log success for debugging
    console.log('API request successful:', {
      url,
      status: response.status,
      sourcesCount: Object.keys(data.sources || {}).length,
      timestamp: new Date().toISOString()
    });

    return data;
  } catch (error) {
    // Log error for debugging
    console.error('API request failed:', {
      url,
      error: {
        message: error.message,
        name: error.name,
        status: error.status,
        code: error.code,
        retryAfter: error.retryAfter
      },
      timestamp: new Date().toISOString()
    });

    // Enhance error with retry information
    if (error.status === 503 && !error.retryAfter) {
      error.retryAfter = 300; // Default 5 minutes for service unavailable
    }

    throw error;
  }
};

export const PriceAPI = {
  comparePrices: async (amount, platform = null, method = null) => {
    if (!amount || amount < 100000 || amount > 5000000) {
      throw new ApiError(
        'Amount must be between 100,000 and 5,000,000',
        400,
        'INVALID_AMOUNT'
      );
    }

    if (!platform) {
      throw new ApiError(
        'Platform is required',
        400,
        'INVALID_PLATFORM'
      );
    }

    // Build URL with query parameters
    const params = new URLSearchParams();
    params.append('platform', platform.toLowerCase());
    if (method) params.append('method', method.toLowerCase());

    const url = `${API_BASE_URL}/api/v1/prices/compare/${amount}${params.toString() ? `?${params.toString()}` : ''}`;

    // Log request parameters
    console.log('Price comparison request:', {
      amount,
      platform,
      method,
      url,
      timestamp: new Date().toISOString()
    });

    return makeRequest(url);
  },

  getBestPrice: async (amount, platform = null, method = null) => {
    if (!amount || amount < 100000 || amount > 5000000) {
      throw new ApiError(
        'Amount must be between 100,000 and 5,000,000',
        400,
        'INVALID_AMOUNT'
      );
    }

    if (!platform) {
      throw new ApiError(
        'Platform is required',
        400,
        'INVALID_PLATFORM'
      );
    }

    // Build URL with query parameters
    const params = new URLSearchParams();
    params.append('platform', platform.toLowerCase());
    if (method) params.append('method', method.toLowerCase());

    const url = `${API_BASE_URL}/api/v1/prices/best/${amount}${params.toString() ? `?${params.toString()}` : ''}`;

    // Log request parameters
    console.log('Best price request:', {
      amount,
      platform,
      method,
      url,
      timestamp: new Date().toISOString()
    });

    return makeRequest(url);
  }
};
