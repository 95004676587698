import { createContext, useContext, useReducer, useCallback } from 'react';
import { PriceAPI } from '../services/api';

const FormContext = createContext(null);

const initialState = {
  steps: {
    console: { 
      value: '', 
      isValid: false, 
      touched: false,
      error: null 
    },
    method: { 
      value: 'comfort_trade',
      isValid: true,
      touched: true,
      error: null 
    },
    coins: { 
      value: { 
        amount: 1000000,
        calculatedPrice: null  // Start with no price
      },
      isValid: true, 
      touched: false,
      error: null 
    }
  },
  currentStep: 'console',
  stepOrder: ['console', 'method', 'coins'],
  results: [],
  isLoading: false,
  error: null,
  errorDetails: null,
  lastComparisonTimestamp: null,
  retryCount: 0,
  lastError: null,
  performance: null,
  isCached: false,
  isUpdating: false,
  priceMetadata: null,
  serviceStatus: 'ready',
  retryAfter: null,
  retryTimestamp: null
};

const VALIDATION_RULES = {
  console: (value) => ({
    isValid: ['playstation', 'xbox', 'pc'].includes(value.toLowerCase()),
    error: 'Please select a valid console'
  }),
  method: (value) => ({
    isValid: value === 'comfort_trade',
    error: 'Only Comfort Trade method is available'
  }),
  coins: (value) => ({
    isValid: value.amount >= 100000 && value.amount <= 5000000,
    error: 'Amount must be between 100K and 5M coins'
  })
};

const ACTION_TYPES = {
  UPDATE_STEP: 'UPDATE_STEP',
  SET_STEP: 'SET_STEP',
  SET_RESULTS: 'SET_RESULTS',
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_SERVICE_STATUS: 'SET_SERVICE_STATUS',
  RESTORE_DATA: 'RESTORE_DATA',
  RESET_FORM: 'RESET_FORM',
  INCREMENT_RETRY: 'INCREMENT_RETRY',
  RESET_RETRY: 'RESET_RETRY',
  CLEAR_RESULTS: 'CLEAR_RESULTS',
  SET_PERFORMANCE: 'SET_PERFORMANCE',
  SET_PRICE_METADATA: 'SET_PRICE_METADATA',
  UPDATE_PRICE: 'UPDATE_PRICE',
  SET_RETRY_INFO: 'SET_RETRY_INFO'
};

function formReducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_STEP:
      if (action.step === 'coins') {
        return {
          ...state,
          steps: {
            ...state.steps,
            [action.step]: {
              ...state.steps[action.step],
              value: {
                amount: action.value.amount,
                calculatedPrice: null  // Clear price when amount changes
              },
              isValid: action.isValid,
              error: action.error,
              touched: true
            }
          }
        };
      }
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.step]: {
            ...state.steps[action.step],
            value: action.value,
            isValid: action.isValid,
            error: action.error,
            touched: true
          }
        }
      };

    case ACTION_TYPES.UPDATE_PRICE:
      return {
        ...state,
        steps: {
          ...state.steps,
          coins: {
            ...state.steps.coins,
            value: {
              ...state.steps.coins.value,
              calculatedPrice: action.price
            }
          }
        }
      };

    case ACTION_TYPES.SET_STEP:
      return { 
        ...state, 
        currentStep: action.step,
        error: null,
        results: action.step !== 'coins' ? [] : state.results
      };

    case ACTION_TYPES.SET_ERROR:
      return { 
        ...state, 
        error: action.error,
        errorDetails: action.details,
        isLoading: false,
        serviceStatus: action.serviceStatus || 'ready',
        lastError: {
          message: action.error,
          details: action.details,
          timestamp: new Date().toISOString()
        },
        retryAfter: action.retryAfter || null,
        retryTimestamp: action.retryAfter ? new Date(Date.now() + action.retryAfter * 1000).toISOString() : null
      };

    case ACTION_TYPES.SET_SERVICE_STATUS:
      return {
        ...state,
        serviceStatus: action.status,
        error: action.error || null,
        errorDetails: action.details || null,
        isLoading: false,
        retryAfter: action.retryAfter || null,
        retryTimestamp: action.retryAfter ? new Date(Date.now() + action.retryAfter * 1000).toISOString() : null
      };

    case ACTION_TYPES.SET_RESULTS:
      return {
        ...state,
        results: action.results,
        lastComparisonTimestamp: new Date().toISOString(),
        retryCount: 0,
        isLoading: false,
        error: null,
        errorDetails: null,
        serviceStatus: 'ready',
        isCached: action.isCached,
        retryAfter: null,
        retryTimestamp: null
      };

    case ACTION_TYPES.SET_LOADING:
      if (action.isLoading) {
        return { 
          ...state, 
          isLoading: true,
          error: null,
          errorDetails: null,
          steps: {
            ...state.steps,
            coins: {
              ...state.steps.coins,
              value: {
                ...state.steps.coins.value,
                calculatedPrice: null  // Clear price when loading starts
              }
            }
          }
        };
      }
      return {
        ...state,
        isLoading: false
      };

    case ACTION_TYPES.SET_PERFORMANCE:
      return {
        ...state,
        performance: action.performance
      };

    case ACTION_TYPES.SET_PRICE_METADATA:
      return {
        ...state,
        priceMetadata: action.metadata
      };

    case ACTION_TYPES.INCREMENT_RETRY:
      return {
        ...state,
        retryCount: state.retryCount + 1
      };

    case ACTION_TYPES.RESET_RETRY:
      return {
        ...state,
        retryCount: 0,
        retryAfter: null,
        retryTimestamp: null
      };

    case ACTION_TYPES.CLEAR_RESULTS:
      return {
        ...state,
        results: [],
        error: null,
        errorDetails: null,
        performance: null,
        priceMetadata: null,
        isUpdating: false,
        serviceStatus: 'ready',
        retryAfter: null,
        retryTimestamp: null,
        steps: {
          ...state.steps,
          coins: {
            ...state.steps.coins,
            value: {
              ...state.steps.coins.value,
              calculatedPrice: null
            }
          }
        }
      };

    case ACTION_TYPES.RESTORE_DATA:
      return {
        ...initialState,
        steps: {
          ...initialState.steps,
          console: {
            ...initialState.steps.console,
            value: action.payload?.console || '',
            isValid: !!action.payload?.console
          },
          method: initialState.steps.method,
          coins: {
            ...initialState.steps.coins,
            value: {
              amount: action.payload?.coins || initialState.steps.coins.value.amount,
              calculatedPrice: null
            }
          }
        }
      };

    case ACTION_TYPES.RESET_FORM:
      return {
        ...initialState,
        lastComparisonTimestamp: state.lastComparisonTimestamp
      };

    default:
      return state;
  }
}

export function FormProvider({ children }) {
  const [state, dispatch] = useReducer(formReducer, initialState);

  const updateStep = useCallback((step, value) => {
    if (step === 'method') {
      value = 'comfort_trade';
    }

    const validation = VALIDATION_RULES[step](value);

    dispatch({
      type: ACTION_TYPES.UPDATE_STEP,
      step,
      value,
      isValid: validation.isValid,
      error: validation.isValid ? null : validation.error
    });
  }, []);

  const handleServiceError = useCallback((error) => {
    if (error.status === 404) {
      dispatch({
        type: ACTION_TYPES.SET_SERVICE_STATUS,
        status: 'updating',
        error: 'Price service is currently updating',
        details: {
          message: 'Our system is gathering the latest prices. This usually takes a few minutes.',
          suggestion: 'Please try again shortly.'
        },
        retryAfter: error.retryAfter || 300
      });
    } else if (error.status === 503) {
      dispatch({
        type: ACTION_TYPES.SET_SERVICE_STATUS,
        status: 'unavailable',
        error: 'Service temporarily unavailable',
        details: {
          message: 'The price comparison service is currently unavailable.',
          suggestion: 'Please try again in a few minutes.'
        },
        retryAfter: error.retryAfter || 300
      });
    } else {
      dispatch({
        type: ACTION_TYPES.SET_ERROR,
        error: error.message,
        details: {
          code: error.code,
          timestamp: new Date().toISOString()
        },
        serviceStatus: 'ready',
        retryAfter: error.retryAfter
      });
    }
  }, []);

  const findBestDeals = useCallback(async () => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, isLoading: true });
    dispatch({ type: ACTION_TYPES.CLEAR_RESULTS });

    try {
      const amount = state.steps.coins.value.amount;
      const platform = state.steps.console.value;
      const method = state.steps.method.value;

      if (!platform) {
        throw new Error('Please select a platform first');
      }

      console.log('Finding best deals:', {
        amount,
        platform,
        method,
        timestamp: new Date().toISOString()
      });

      const response = await PriceAPI.comparePrices(amount, platform, method);

      console.log('Raw API response:', response);

      if (!response || !response.sources) {
        throw new Error('No prices available at the moment');
      }

      const formattedResults = Object.entries(response.sources)
        .map(([site, data]) => {
          const price = data.price?.amount;
          if (typeof price !== 'number') {
            console.warn(`Invalid price for ${site}:`, data.price);
            return null;
          }

          // Remove _combined suffix from display name but keep the combined order info
          const displayName = site.replace('_combined', '').toUpperCase();

          return {
            site: displayName,
            price: price,
            currency: data.price?.currency || 'USD',
            deliveryTime: data.deliveryTime || { estimation: 'Unknown' },
            bonus: data.bonus,
            url: data.website?.url,
            platform: data.platform,
            method: data.method,
            lastChecked: data.lastChecked,
            isStale: data.isStale,
            age: data.age,
            isCombinedOrder: data.isCombinedOrder || site.includes('_combined'),
            orders: data.orders || null
          };
        })
        .filter(result => result && result.price && result.url)
        .sort((a, b) => a.price - b.price);

      if (formattedResults.length === 0) {
        throw new Error('No valid prices available for your selection');
      }

      if (response.performance) {
        dispatch({
          type: ACTION_TYPES.SET_PERFORMANCE,
          performance: response.performance
        });
      }

      if (response.metadata) {
        dispatch({
          type: ACTION_TYPES.SET_PRICE_METADATA,
          metadata: response.metadata
        });
      }

      dispatch({ 
        type: ACTION_TYPES.SET_RESULTS, 
        results: formattedResults,
        isCached: response.performance?.isCached || false
      });

      // Update price with the lowest price found
      if (formattedResults.length > 0) {
        dispatch({
          type: ACTION_TYPES.UPDATE_PRICE,
          price: formattedResults[0].price
        });
      }

      return formattedResults;
    } catch (error) {
      console.error('Price comparison failed:', {
        error,
        timestamp: new Date().toISOString(),
        state: {
          platform: state.steps.console.value,
          method: state.steps.method.value,
          amount: state.steps.coins.value.amount
        }
      });

      handleServiceError(error);
      return [];
    }
  }, [state.steps.coins.value.amount, state.steps.console.value, state.steps.method.value, handleServiceError]);

  const clearResults = useCallback(() => {
    dispatch({ type: ACTION_TYPES.CLEAR_RESULTS });
  }, []);

  const value = {
    state,
    dispatch,
    updateStep,
    canNavigate: useCallback((fromStep, toStep) => {
      if (!toStep) return false;

      const currentIndex = state.stepOrder.indexOf(fromStep);
      const targetIndex = state.stepOrder.indexOf(toStep);

      if (targetIndex <= currentIndex) return true;

      return state.stepOrder
        .slice(0, targetIndex)
        .every(step => state.steps[step].isValid);
    }, [state.stepOrder, state.steps]),
    findBestDeals,
    clearResults,
    ACTION_TYPES
  };

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

export const useForm = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useForm must be used within FormProvider');
  }
  return context;
};
